<template>
  <div>
    <van-nav-bar title="绑定手机号" left-arrow @click-left="$router.go(-1)" />
    <div class="content">
      <van-form @submit="onSubmit" @failed="failed" :show-error-message="false">
        <van-field
          class="field-input"
          v-model="phone"
          name="phone"
          maxlength="11"
          type="number"
          placeholder="请输入手机号"
          :rules="[
            {
              required: true,
              message: '请填写手机号码'
            },
            {
              pattern: /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/,
              message: '请输入正确手机号码'
            }
          ]"
        />
        <van-field
          class="field-input"
          v-model="keyCode"
          name="keyCode"
          type="number"
          placeholder="请填写验证码"
          maxlength="6"
          :rules="[
            { required: true, message: '请填写验证码' },
            {
              pattern: /^\d{6}$/,
              message: '请输入正确验证码'
            }
          ]"
        >
          <template #right-icon>
            <div v-show="codeShow" class="VerificationCode" @click="getCode">
              获取验证码
            </div>
            <div v-show="!codeShow" class="VerificationCode">
              {{ yzmTitle }}
            </div>
          </template>
        </van-field>
        <van-field
          v-if="showinviteCode"
          class="field-input"
          v-model="invite_code"
          placeholder="请填写邀请码"
          :rules="[{ required: true, message: '请填写邀请码' }]"
        />
        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit"
            >下一步</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { bindCode, getCode } from "@/api/login";
export default {
  data() {
    return {
      phone: "",
      keyCode: "",
      yzmTitle: "",
      codeShow: true,
      invite_code: "",
      showinviteCode: false
    };
  },
  methods: {
    onSubmit(values) {
      console.log(values);
      bindCode({
        code: this.keyCode,
        openid: this.$route.query.openid,
        mobile: this.phone,
        invite_code: this.invite_code
      }).then(res => {
        console.log(res);
        if (res.data.code == 202) {
          this.showinviteCode = true;
          this.$toast("请填写邀请码");
        }
        if (res.data.code == 200) {
          this.$toast("绑定成功请重新登录");
          this.$router.replace({
            path: "/login"
          });
        }
      });
    },
    failed(e) {
      console.log("错误", e);
      this.$toast(e.errors[0].message);
    },
    getCode() {
      let phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
      if (!phonereg.test(this.phone)) {
        this.$toast("手机号格式不正确");
        return;
      }
      if (!this.phone) {
        this.$toast("请输入手机号码");
        return;
      }

      let params = {
        mobile: this.phone
      };
      getCode(params).then(res => {
        console.log(res);
        if (res.data.code == 200) {
          let time = 60;
          let timer = setInterval(() => {
            if (time == 0) {
              clearInterval(timer);
              this.codeShow = true;
            } else {
              this.codeShow = false;
              this.yzmTitle = time + "秒后重试";
              time--;
            }
          }, 1000);
          this.$toast("验证码获取成功");
        } else {
          this.$toast(res.data.message);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/.van-nav-bar .van-icon {
  color: #333333;
}
.content {
  padding-top: 80px;
  margin: 0 65px 0 65px;
  display: flex;
  justify-content: space-around;
  height: 750px;
  text-align: center;
  .field-input {
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border-radius: 60px;
  }
  .VerificationCode {
    width: 174px;
    height: 51px;
    border: 1px solid #d2d2d2;
    border-radius: 26px;
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;
    text-align: center;
  }
}
</style>
