var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('van-nav-bar',{attrs:{"title":"绑定手机号","left-arrow":""},on:{"click-left":function($event){return _vm.$router.go(-1)}}}),_c('div',{staticClass:"content"},[_c('van-form',{attrs:{"show-error-message":false},on:{"submit":_vm.onSubmit,"failed":_vm.failed}},[_c('van-field',{staticClass:"field-input",attrs:{"name":"phone","maxlength":"11","type":"number","placeholder":"请输入手机号","rules":[
          {
            required: true,
            message: '请填写手机号码'
          },
          {
            pattern: /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/,
            message: '请输入正确手机号码'
          }
        ]},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('van-field',{staticClass:"field-input",attrs:{"name":"keyCode","type":"number","placeholder":"请填写验证码","maxlength":"6","rules":[
          { required: true, message: '请填写验证码' },
          {
            pattern: /^\d{6}$/,
            message: '请输入正确验证码'
          }
        ]},scopedSlots:_vm._u([{key:"right-icon",fn:function(){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.codeShow),expression:"codeShow"}],staticClass:"VerificationCode",on:{"click":_vm.getCode}},[_vm._v(" 获取验证码 ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.codeShow),expression:"!codeShow"}],staticClass:"VerificationCode"},[_vm._v(" "+_vm._s(_vm.yzmTitle)+" ")])]},proxy:true}]),model:{value:(_vm.keyCode),callback:function ($$v) {_vm.keyCode=$$v},expression:"keyCode"}}),(_vm.showinviteCode)?_c('van-field',{staticClass:"field-input",attrs:{"placeholder":"请填写邀请码","rules":[{ required: true, message: '请填写邀请码' }]},model:{value:(_vm.invite_code),callback:function ($$v) {_vm.invite_code=$$v},expression:"invite_code"}}):_vm._e(),_c('div',{staticStyle:{"margin":"16px"}},[_c('van-button',{attrs:{"round":"","block":"","type":"info","native-type":"submit"}},[_vm._v("下一步")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }